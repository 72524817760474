import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Skeleton from '@/components/Skeleton';

import type { OptionContentProps } from '../types';

const OptionContent = ({ option, type }: OptionContentProps) => {
  const [loading, setLoading] = useState(true);

  if (option?.color) {
    return (
      <div
        className={twMerge(
          'h-4 w-4 rounded border-[1px] border-disabled',
          type === 'option' && 'mr-3',
          type === 'control' && 'ml-2 self-center'
        )}
        style={{
          backgroundColor: option.color.includes('#')
            ? option.color
            : `#${option.color}`
        }}
      />
    );
  } else if (option?.image) {
    return (
      <>
        <div
          className={twMerge(
            'checkerboard flex min-w-[62px] items-center justify-center',
            type === 'option' && 'mr-3',
            type === 'control' && 'ml-3'
          )}
        >
          {loading && <Skeleton className="h-8 w-full" full />}
          <img
            className={`h-8 w-fit p-1 ${loading ? 'hidden' : ''}`}
            onLoad={() => setLoading(false)}
            src={option.image}
          />
        </div>
      </>
    );
  } else return null;
};

export default OptionContent;
