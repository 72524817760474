import { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useAuth } from '@/hooks';
import { useCompanyChange } from '@/hooks/useCompanyChange';
import { TableContext } from '@/providers';
import server from '@/services/server';
import { appendParams } from '@/utils/url';

import Select from '../Select';

const CompanySelect = () => {
  const { pathname } = useLocation();
  const { emitCompanyChangedEvent } = useCompanyChange();
  const [, setSearchParams] = useSearchParams();
  const { user, mutate: refreshUser } = useAuth();
  const {
    actions: { clearSelection }
  } = useContext(TableContext);

  const { control, watch, resetField } = useForm({
    mode: 'onChange',
    defaultValues: {
      company: {
        value: user?.selectedCompany?.id,
        label: user?.selectedCompany?.name
      }
    }
  });

  useEffect(() => {
    resetField('company', {
      defaultValue: {
        value: user?.selectedCompany?.id,
        label: user?.selectedCompany?.name
      }
    });
  }, [user?.selectedCompany?.id]);

  const currentAccessCompanySelector = useMemo(
    () =>
      user?.accesses?.find(
        ({ path, subpaths }) =>
          pathname.includes(path) ||
          subpaths?.some(subpath => pathname.includes(subpath))
      )?.companySelector,
    [user?.accesses, pathname]
  );

  useEffect(() => {
    const onSelectCompany = async (companyId?: number) => {
      if (!companyId) return;

      clearSelection();
      setSearchParams(new URLSearchParams(), { replace: true });
      await server.patch({
        data: { selectedCompanyId: companyId },
        path: 'current_company'
      });
      emitCompanyChangedEvent();
      refreshUser();
    };

    const subscription = watch(values => {
      onSelectCompany(values.company?.value);
    });

    return () => subscription.unsubscribe();
  }, [emitCompanyChangedEvent, watch]);

  const visible = () => {
    if (pathname.includes('/new') || pathname.includes('/duplicate')) {
      return currentAccessCompanySelector?.new;
    }

    if (pathname.includes('/edit') || pathname.includes('/show')) {
      return currentAccessCompanySelector?.edit;
    }

    return currentAccessCompanySelector?.index;
  };

  const disabled = () => {
    if (pathname.includes('/new') || pathname.includes('/duplicate')) {
      return currentAccessCompanySelector?.new?.disabled;
    }

    if (pathname.includes('/edit') || pathname.includes('/show')) {
      return currentAccessCompanySelector?.edit?.disabled;
    }

    return currentAccessCompanySelector?.index?.disabled;
  };

  const tool = pathname === '/next/users' ? 'dashboard_user' : null;

  return visible() ? (
    <Select
      className="w-55"
      clearable={false}
      control={control}
      disabled={disabled()}
      endpointVersion={1}
      mapper={({ id, name }) => ({ label: name, value: id })}
      mapperValueAccessor="companies"
      menuListClassName="max-h-[40rem]"
      name="company"
      optionsUrl={appendParams('companies', { tool })}
      searchParamName="term"
    />
  ) : (
    <div />
  );
};

export default CompanySelect;
