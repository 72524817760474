import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { MOCKS_ENABLED } from '@/environment';
import { useAuth } from '@/hooks';
import { redirectToSignIn } from '@/utils/redirectToSignIn';

type RequireAuthProps = {
  children: ReactNode;
};

const RequireAuth = ({ children }: RequireAuthProps) => {
  const { user, isLoading } = useAuth();
  const { pathname } = useLocation();

  if (pathname.startsWith('/manage/')) {
    location.reload();
  }

  if (!isLoading && !user?.name && !MOCKS_ENABLED) {
    redirectToSignIn();
    // TODO: When we rewrite login page to React, redirect using react router instead of window.location.href
    // State saves the current location they were so we can redirect them back after logging in
    // To do this, uncomment the next line
    // return <Navigate replace state={{ from: location }} to="/users/sign_in" />;
    // and add the next line to the login page
    // navigate(location.state?.from?.pathname || '/', { replace: true });
  }

  return user?.name ? <>{children}</> : <></>;
};

export default RequireAuth;
