import { useEffect, useRef } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import Button from '@/components/Button';

const Fallback = ({ resetErrorBoundary }: FallbackProps) => {
  const { pathname, search, hash } = useLocation();
  const prevLocation = useRef({ pathname, search, hash });

  useEffect(() => {
    if (
      prevLocation.current.pathname !== pathname ||
      prevLocation.current.search !== search ||
      prevLocation.current.hash !== hash
    ) {
      resetErrorBoundary();
      prevLocation.current = { pathname, search, hash };
    }
  }, [pathname, search, hash, resetErrorBoundary]);

  return (
    <div className="m-auto flex h-[calc(100%-70px)] max-w-xs flex-col items-center justify-center gap-7 text-center">
      <h1
        className="mb-0 text-8xl font-bold text-buttons-default"
        style={{
          textShadow:
            'rgba(114, 124, 245, 0.3) 5px 1px,rgba(114, 124, 245, 0.2) 10px 3px'
        }}
      >
        500
      </h1>
      <h4 className="text-lg font-bold uppercase text-error">
        Internal server error
      </h4>
      <p>We&apos;re sorry, but something went wrong.</p>
      <Button redirectTo="/">Return Home</Button>
    </div>
  );
};

export default Fallback;
