import { lazy as reactLazy, useEffect } from 'react';

import Button from '@/components/Button';

const FallbackComponent = () => {
  const refreshPage = () => {
    caches.keys().then(names => {
      names.forEach(name => {
        caches.delete(name);
      });
    });

    window.location.reload();
  };

  useEffect(() => {
    refreshPage();
  }, []);

  return (
    <div className="m-auto flex h-[calc(100%-70px)] flex-col items-center justify-center gap-5 text-center">
      <p className="text-lg font-bold uppercase text-accent">
        Loading a new version of the app...
      </p>
      <p>If the page is not refreshed automatically, click the button below.</p>
      <Button outlined>Refresh Page</Button>
    </div>
  );
};

const lazy = (importFunc: () => Promise<any>) =>
  reactLazy(async () => {
    try {
      return await importFunc();
    } catch {
      return { default: FallbackComponent };
    }
  });

export default lazy;
