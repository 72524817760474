import { components } from 'react-select';

const MultiValue: typeof components.MultiValue = props => {
  const { index, getValue } = props;

  const items = getValue().length;

  const hideMultiValue = (props.selectProps as any).hideMultiValue;

  return hideMultiValue ? (
    <>
      {items < 2 ? (
        <components.MultiValue {...props} />
      ) : index === 0 ? (
        `${items} items selected`
      ) : null}
    </>
  ) : (
    <components.MultiValue {...props} />
  );
};

export default MultiValue;
