export const filterEmptyParams = (
  params: Record<string, any>
): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== null && value !== ''
    )
  );
};

export const appendParams = (path: string, params: Record<string, any>) => {
  const hasBase = /^https?:\/\//i.test(path);
  const urlObj = new URL(path, hasBase ? undefined : 'http://dummy-base');
  const filteredParams = filterEmptyParams(params);

  Object.keys(filteredParams).forEach(key => {
    urlObj.searchParams.append(key, String(filteredParams[key]));
  });

  return hasBase ? urlObj.toString() : urlObj.pathname + urlObj.search;
};
