import { useState } from 'react';
import { Collapse } from 'react-collapse';
import { useLocation } from 'react-router-dom';

import Svg from '@/components/Svg';
import { t } from '@/translations/i18n';

import type { Supercategory as SupercategoryType } from '../types';
import Item from './Item';

type SupercategoryProps = {
  item: SupercategoryType;
};

const Supercategory = ({ item }: SupercategoryProps) => {
  const { key, items } = item;

  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(
    !items.some(item => `/${item.path}` === pathname)
  );
  const [hovered, setHovered] = useState(false);

  return (
    <>
      <button
        className="group flex h-[43.53px] w-full -translate-x-1 items-center justify-between py-2.5 pl-12 pr-6 pt-[9px]"
        onClick={() => setCollapsed(collapsed => !collapsed)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <span className="text-[15px] text-tertiary transition-colors duration-300 ease-out group-hover:text-linkHover">
          {t(`nav.${key}`)}
        </span>
        <Svg
          hoverStroke="#BCCEE4"
          icon="arrowUp"
          isHovered={hovered}
          rotate={collapsed}
        />
      </button>

      <Collapse isOpened={!collapsed}>
        <ul className="flex flex-col">
          {items.map(item => (
            <li key={item.key}>
              <Item item={item} subitem />
            </li>
          ))}
        </ul>
      </Collapse>
    </>
  );
};

export default Supercategory;
