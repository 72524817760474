import { components } from 'react-select';

import Svg from '@/components/Svg';

import type { OptionType } from '../types';
import OptionContent from './OptionContent';

const Option: typeof components.Option = props => {
  const {
    data,
    children,
    isSelected,
    selectProps: { isMulti }
  } = props;

  return (
    <components.Option {...props}>
      <OptionContent option={data as OptionType} type="option" />
      {children}
      {isSelected && isMulti && (
        <Svg className="ml-auto min-w-5" icon="tick" stroke="#536DE6" />
      )}
    </components.Option>
  );
};

export default Option;
